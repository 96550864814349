import * as React from "react";
import { DefaultButton } from "@fluentui/react";
import Header from "./Header";
import AttributeList, { AttributeListItem } from "./AttributeList";
import Progress from "./Progress";
import Select from "react-select";
import makeAnimated from "react-select/animated";

import { getMiddleTierData } from "../../helpers/sso-helper";
import { createPngWithMetadata, stripOdataAndFlatten, ProjectMetadata } from "../../helpers/data-helper";
import { findImageFromItem } from "../../helpers/office-item-helper";
import { MiddleTierRequestManager } from "../../helpers/middle-tier-request-manager";
import { storeData } from "../../helpers/runtime-data-sharing-helper";

/* global require */
/* global Office */
/* global console */

const animatedComponents = makeAnimated();

export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export interface User {
  displayName: string;
  mail: string;
  jobTitle: string;
}

export interface AppState {
  currentOfficeItem: Office.Item &
    Office.ItemCompose &
    Office.ItemRead &
    Office.Message &
    Office.MessageCompose &
    Office.MessageRead &
    Office.Appointment &
    Office.AppointmentCompose &
    Office.AppointmentRead;
  listItems: AttributeListItem[];
  projectList: Tuple[];
  tagList: Tuple[];
  loggedInUser: User;
  currentProjectUrl: string;
  currentProjectName: string;
  currentTags: Tuple[];
  userToken: UserTokenInfo;
  imgSrc: string;
  error: boolean;
  message: string;
  projectApplied: boolean;
  alreadyTagged: boolean;
  projectUploadState: "none" | "uploading" | "uploaded";
  appStatus: "initializing" | "ready";
  enableSending: "true" | "false";
}
export interface Tuple {
  label: string;
  value: any;
}
export interface UserTokenInfo {
  name: string;
  preferred_username: string;
  oid: string;
}

export default class App extends React.Component<AppProps, AppState> {
  canvasRef = React.createRef();
  imageRef = React.createRef();
  requestManager = new MiddleTierRequestManager();

  constructor(props, context) {
    super(props, context);
    this.state = {
      currentOfficeItem: null,
      listItems: [],
      projectList: [],
      loggedInUser: null,
      currentProjectUrl: "",
      currentProjectName: "",
      tagList: [],
      currentTags: [],
      imgSrc: "",
      error: false,
      userToken: null,
      message: null,
      projectApplied: false,
      alreadyTagged: false,
      projectUploadState: "none",
      appStatus: "initializing",
      enableSending: "false",
    };

    //for the handlers
    this.officeItemChanged = this.officeItemChanged.bind(this);
  }

  officeItemChanged(eventArgs) {
    console.log("Office item changed " + eventArgs);
    // Update UI based on the new current item
    this.setState({
      appStatus: "initializing",
      currentOfficeItem: Office.context.mailbox.item,
      listItems: [],
      projectList: [],
      tagList: [],
      projectUploadState: "none",
      currentProjectUrl: "",
      currentProjectName: "",
      currentTags: [],
    });
  }

  componentDidUnmount() {
    if (Office) {
      Office.context.mailbox.removeHandlerAsync(Office.EventType.ItemChanged, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          // Handle error
          console.error(result.error.message);
        }
      });
    }
  }

  componentDidMount() {
    if (Office) {
      // Register the event handler for item selection change
      Office.context.mailbox.addHandlerAsync(Office.EventType.ItemChanged, this.officeItemChanged, (result) => {
        if (result.status === Office.AsyncResultStatus.Failed) {
          // Handle error
          console.error(result.error.message);
        }
      });
      this.reloadItem();
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if (this.state.currentOfficeItem !== prevState.currentOfficeItem) this.reloadItem();
  }

  reloadItem() {
    // if item changed
    // try to find metadata
    if (Office.context.mailbox.item) {
      findImageFromItem(Office.context.mailbox.item)
        .then((metadata) => {
          if (metadata) {
            console.log("Metadata found:", metadata);
            this.setState({
              currentProjectName: metadata.projectname,
              currentProjectUrl: metadata.projecturl,
              currentTags: metadata.tags.map((tag) => ({ label: tag, value: tag })),
              alreadyTagged: true,
              listItems: [
                {
                  icon: "Design",
                  primaryText: "Project: " + metadata.projectname,
                },
                {
                  icon: "Link",
                  primaryText: "Open Project",
                  url: metadata.projecturl,
                },
                {
                  icon: "Tag",
                  primaryText: "Tags: " + metadata.tags.join(", "),
                },
              ],
              appStatus: "ready",
              enableSending: "true",
            });
          } else {
            // check in which context are we
            if (this.getItemContext() === "composing" || this.getItemContext() === "reading") {
              // not tagged yet
              // get user data and sharepoint information
              this.requestManager
                .enqueueRequest(() => getMiddleTierData("/getuserdata"))
                .then((response) => {
                  console.log("User data:", response);
                  this.setState({
                    loggedInUser: {
                      displayName: response.displayName,
                      mail: response.mail,
                      jobTitle: response.jobTitle,
                    },
                  });
                })
                .catch((error) => {
                  console.error("Error fetching user data:", error);
                  this.setState({
                    error: true,
                  });
                });

              this.requestManager
                .enqueueRequest(() => getMiddleTierData("/getprojects"))
                .then((response) => {
                  console.log("Projects:", response);
                  let list: Tuple[] = stripOdataAndFlatten(response).map(
                    (project) =>
                      ({
                        label: project.ProjectID_x0020_and_x0020_Title,
                        value: {
                          projectname: project.ProjectID_x0020_and_x0020_Title,
                          projecturl: project.ProjectReadyURL,
                        },
                      } as Tuple)
                  );
                  this.setState({
                    projectList: list,
                  });
                })
                .catch((error) => {
                  console.error("Error fetching projects:", error);
                  this.setState({
                    error: true,
                  });
                });

              this.requestManager
                .enqueueRequest(() => getMiddleTierData("/gettags"))
                .then((response) => {
                  console.log("Tags:", response);
                  let list: Tuple[] = stripOdataAndFlatten(response).map(
                    (project) =>
                      ({
                        label: project.Title,
                        value: project.Title,
                      } as Tuple)
                  );
                  this.setState({
                    tagList: list,
                  });
                })
                .catch((error) => {
                  console.error("Error fetching tags:", error);
                  this.setState({
                    error: true,
                  });
                });
              // this.setState({
              //   listItems: [],
              // });
              this.requestManager
                .enqueueRequest(() => Promise.resolve())
                .then(() => {
                  this.setState({
                    appStatus: "ready",
                  });
                })
                .catch((error) => {
                  console.error("Error in status setting", error);
                  this.setState({
                    error: true,
                  });
                });
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching metadata:", error);
          this.setState({
            error: true,
            appStatus: "ready",
          });
        })
        .finally(() => {
          console.log("reloadItem finished");
          // this.setState({ appStatus: "ready" });
        });
    }
  }

  upClick = async () => {
    //upload eml file to sharepoint
    this.uploadEmlFile();
  };

  uploadEmlFile = async () => {
    this.setState({ projectUploadState: "uploading" });
    // disable the button
    this.requestManager.enqueueRequest(() =>
      getMiddleTierData(
        "/postemailfromid?id=" +
          encodeURIComponent(
            Office.context.mailbox.convertToRestId(
              Office.context.mailbox.item.itemId,
              Office.MailboxEnums.RestVersion.v1_0
            )
          ) +
          "&siteUrl=" +
          encodeURIComponent(this.state.currentProjectUrl) +
          //add tags in json array of strings format, e.g. ["tag1", "tag2"], if there are no tags, pass empty array
          "&tags=" +
          encodeURIComponent(JSON.stringify(this.state.currentTags.map((tag) => tag.value)))
      )
        .then((response) => {
          console.log("Upload data:", response);
          this.setState({
            projectUploadState: "uploaded",
          });
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          this.setState({
            projectUploadState: "none",
          });
        })
    );
  };

  click = async () => {
    // create pngfile with metadata
    this.executeEmbed({
      projectname: this.state.currentProjectName,
      projecturl: this.state.currentProjectUrl,
      tags: this.state.currentTags.map((tag) => tag.value),
    });

    Office.context.mailbox.item.subject.getAsync((result) => {
      if (result.status === Office.AsyncResultStatus.Succeeded) {
        const newSubject = `[${this.state.currentProjectName}] ${result.value}`;
        Office.context.mailbox.item.subject.setAsync(newSubject, (setResult) => {
          if (setResult.status === Office.AsyncResultStatus.Failed) {
            console.error("Error setting the subject:", setResult.error);
          }
        });
      } else {
        console.error("Error getting the subject:", result.error);
      }
    });
  };

  showTaskpaneMessage = (message: string) => {
    this.setState({ message: message });
  };

  executeEmbed = (data: ProjectMetadata) => {
    const dataUrlEncodedImage = createPngWithMetadata(data);

    this.setState({ imgSrc: dataUrlEncodedImage });

    const imgElement = '<img id="tagaddin.png" name="tagaddin.png" src="' + dataUrlEncodedImage + '">';
    // trigger the append on send event and save it to the state so as to disable the selectors
    Office.context.mailbox.item.body.getTypeAsync((asyncResult) => {
      if (asyncResult.status === Office.AsyncResultStatus.Failed) {
        console.log(asyncResult.error.message);
        return;
      }

      // Sets the disclaimer to be appended to the body of the message on send.
      const bodyFormat = asyncResult.value;
      Office.context.mailbox.item.body.appendOnSendAsync(
        imgElement,
        {
          asyncContext: asyncResult.asyncContext,
          coercionType: bodyFormat,
        },
        (asyncResult) => {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            console.log(asyncResult.error.message);
            return;
          }

          console.log("The image will be appended when the mail item is sent.");
          this.setState({ enableSending: "true" });
        }
      );
    });
    this.setState({ projectApplied: true });
  };

  getItemContext = () => {
    if (!Office.context.mailbox.item) return "unknown";

    if (Office.context.mailbox.item.itemType === Office.MailboxEnums.ItemType.Message) {
      if (Office.context.mailbox.item.displayReplyForm) {
        return "reading";
      } else {
        return "composing";
      }
    } else if (Office.context.mailbox.item.itemType === Office.MailboxEnums.ItemType.Appointment) {
      // Handle appointments if needed
    }
    return "unknown";
  };

  renderSelects() {
    const {
      projectList,
      tagList,
      currentTags,
      currentProjectUrl,
      currentProjectName,
      projectApplied,
      projectUploadState,
    } = this.state;

    return (
      <>
        <br></br>
        <Select
          value={{
            label: currentProjectName,
            value: { projectname: currentProjectName, projecturl: currentProjectUrl },
          }}
          isDisabled={projectApplied || projectUploadState !== "none"}
          className="ms-welcome__selectS"
          options={projectList}
          onChange={(choice) => {
            this.setState({
              currentProjectUrl: choice.value.projecturl,
              currentProjectName: choice.value.projectname,
            });
          }}
        />
        <br></br>
        <Select
          value={currentTags}
          className="ms-welcome__select"
          options={tagList}
          components={animatedComponents}
          isMulti
          isDisabled={projectApplied || projectUploadState !== "none"}
          closeMenuOnSelect={false}
          onChange={(choice: []) =>
            this.setState({
              currentTags: choice,
            })
          }
        />
        <br></br>
      </>
    );
  }

  renderAttributeList() {
    const {
      loggedInUser,
      projectList,
      tagList,
      listItems,
      error,
      alreadyTagged,
      projectApplied,
      projectUploadState,
      appStatus,
    } = this.state;
    const itemContext = this.getItemContext();
    let attributeListContent;

    if (itemContext === "composing" && !alreadyTagged) {
      let headerMessage = "";
      let attributeListBody = <></>;
      let applyButton = <br></br>;
      if (loggedInUser === null || projectList.length === 0 || tagList.length === 0) {
        // loading
        headerMessage = "Loading...";
      } else if (error) {
        // not authorized
        headerMessage = "Not authorized";
      } else {
        // authorized
        headerMessage = "Project information for this email";
        attributeListBody = this.renderSelects();
        applyButton = (
          <DefaultButton
            className="ms-welcome__action"
            iconProps={{ iconName: "ChevronRight" }}
            onClick={this.click}
            disabled={projectApplied}
          >
            Apply
          </DefaultButton>
        );
      }
      attributeListContent = (
        <AttributeList message={headerMessage} items={listItems}>
          <br></br>
          {attributeListBody}
          <br></br>
          {applyButton}
        </AttributeList>
      );
    } else {
      let showUploadButton = false;
      let attributeListBody = <></>;
      let footerMessage = "";
      let headerMessage = "";
      if (this.getItemContext() === "reading") {
        if (appStatus === "initializing") {
          headerMessage = "Loading...";
        } else if (error || listItems.length === 0) {
          headerMessage = "No project data found in this email";
          // todo add selects in attribute list and add button to upload anyway
          attributeListBody = this.renderSelects();
          showUploadButton = true;
        } else {
          headerMessage = "Project Information for this email";
          showUploadButton = true;
        }
      } else {
        headerMessage = "Project information";
      }
      if (projectUploadState === "uploading") {
        footerMessage = "Uploading to project site...";
      } else if (projectUploadState === "uploaded") {
        footerMessage = "Uploaded to project site! " + String.fromCodePoint(0x2705);
      }
      let uploadButton;
      if (showUploadButton) {
        uploadButton = (
          <DefaultButton
            className="ms-welcome__action"
            iconProps={{ iconName: "Up" }}
            onClick={this.upClick}
            disabled={projectUploadState !== "none"}
          >
            Upload to project site
          </DefaultButton>
        );
      }
      attributeListContent = (
        <AttributeList message={headerMessage} items={listItems}>
          <br></br>
          {attributeListBody}
          <br></br>
          {uploadButton}
          <br></br>
          {footerMessage}
        </AttributeList>
      );
    }

    return attributeListContent;
  }

  render() {
    const { title, isOfficeInitialized } = this.props;

    if (!isOfficeInitialized) {
      return (
        <Progress
          title={title}
          logo={require("./../../../assets/logo-filled.png")}
          message="Office is not initialized."
        />
      );
    }
    storeData("enableSending", this.state.enableSending, (error) => {
      if (error) {
        console.log("Error storing enableSending " + error);
      } else {
        // console.log("enableSending " + this.state.enableSending + " stored");
      }
    });
    const greeting = "Welcome" + (this.state.loggedInUser ? ", " + this.state.loggedInUser.displayName : "") + "!";
    let attributeList = this.renderAttributeList();

    return (
      <div className="ms-welcome">
        <Header logo={require("./../../../assets/logo-filled.png")} title={this.props.title} message={greeting} />
        {attributeList}
      </div>
    );
  }
}

/* global Office, window, console */

export function storeData(key: string, data: string, callback?: (error: Error) => void) {
  var dataString = JSON.stringify(data);
  console.log("storeData called");
  console.log("platform: " + Office.context.platform);
  if (Office.context.platform === Office.PlatformType.PC) {
    if (!Office.context.mailbox.item) {
      //wait 1s for outlook to load
      window.setTimeout(() => {
        storeData(key, data, callback);
      }, 1000);
    } else {
      if (Office.context.mailbox.item.sessionData) {
        Office.context.mailbox.item.sessionData.setAsync(key, dataString, function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            callback && callback(new Error(asyncResult.error.message));
          } else {
            callback && callback(null);
          }
        });
      } else {
        // use mail custom properties
        Office.context.mailbox.item.loadCustomPropertiesAsync(function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            callback && callback(new Error(asyncResult.error.message));
          } else {
            var customProps = asyncResult.value;
            customProps.set(key, dataString);
            customProps.saveAsync(function (asyncResult) {
              if (asyncResult.status === Office.AsyncResultStatus.Failed) {
                callback && callback(new Error(asyncResult.error.message));
              } else {
                callback && callback(null);
              }
            });
          }
        });
      }
    }
  } else {
    window.localStorage.setItem(key, dataString);
    callback && callback(null);
  }
}

export function getData(key: string, callback: (error: Error, data: any) => void): void {
  console.log("getData called");
  console.log("platform: " + Office.context.platform);
  if (Office.context.platform === Office.PlatformType.PC) {
    if (!Office.context.mailbox.item) {
      //wait 1s for outlook to load
      window.setTimeout(() => {
        getData(key, callback);
      }, 1000);
    } else {
      if (Office.context.mailbox.item.sessionData) {
        Office.context.mailbox.item.sessionData.getAsync(key, function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
            callback(null, JSON.parse(asyncResult.value));
          } else {
            callback(new Error(asyncResult.error.message), null);
          }
        });
      } else {
        // use mail item custom properties
        Office.context.mailbox.item.loadCustomPropertiesAsync(function (asyncResult) {
          if (asyncResult.status === Office.AsyncResultStatus.Failed) {
            callback && callback(new Error(asyncResult.error.message), null);
          } else {
            var customProps = asyncResult.value;
            let value = customProps.get(key);

            if (!value) {
              callback && callback(new Error(asyncResult.error.message), null);
            } else {
              callback && callback(null, JSON.parse(value));
            }
          }
        });
      }
    }
  } else {
    var data = window.localStorage.getItem(key);
    callback(null, JSON.parse(data));
  }
}

export class MiddleTierRequestManager {
  private queue: Array<() => Promise<any>> = [];
  private isProcessing: boolean = false;

  async processQueue() {
    if (this.queue.length === 0 || this.isProcessing) {
      return;
    }

    this.isProcessing = true;
    try {
      const requestFunction = this.queue.shift(); // Take the first function from the queue
      if (requestFunction) {
        await requestFunction(); // Execute it
      }
    } finally {
      this.isProcessing = false;
      this.processQueue(); // Recursive call to process next item in the queue if any
    }
  }

  enqueueRequest(requestFunction: () => Promise<any>): Promise<any> {
    return new Promise((resolve, reject) => {
      // Wrap the original function to catch its resolution or rejection
      const wrappedFunction = async () => {
        try {
          const result = await requestFunction();
          resolve(result);
        } catch (error) {
          reject(error);
        }
      };

      this.queue.push(wrappedFunction); // Push this wrapped function to the queue
      this.processQueue(); // Process the queue
    });
  }
}

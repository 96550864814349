import * as React from "react";

export interface AttributeListItem {
  icon: string;
  primaryText: string;
  url?: string;
}

export interface AttributeListProps {
  message: string;
  items: AttributeListItem[];
  children?: any;
}

export default class AttributeList extends React.Component<AttributeListProps> {
  render() {
    const { children, items, message } = this.props;

    const listItems = items.map((item, index) => (
      <li className="ms-ListItem" key={index}>
        <i className={`ms-Icon ms-Icon--${item.icon}`}></i>
        {item.url ? (
          <a href={item.url} target="_blank" rel="noreferrer" className="ms-font-m ms-fontColor-neutralPrimary">
            {item.primaryText}
          </a>
        ) : (
          <span className="ms-font-m ms-fontColor-neutralPrimary">{item.primaryText}</span>
        )}
      </li>
    ));
    return (
      <main className="ms-welcome__main">
        <h2 className="ms-font-xl ms-fontWeight-semilight ms-fontColor-neutralPrimary ms-u-slideUpIn20">{message}</h2>
        <ul className="ms-List ms-welcome__features ms-u-slideUpIn10">{listItems}</ul>
        {children}
      </main>
    );
  }
}

import { ProjectMetadata, extractImgSrc, readMetadataFromImage, validateMetadata } from "./data-helper";

/* global Office, console, Promise */

export async function findImageFromItem(
  item: Office.Item &
    Office.ItemCompose &
    Office.ItemRead &
    Office.Message &
    Office.MessageCompose &
    Office.MessageRead &
    Office.Appointment &
    Office.AppointmentCompose &
    Office.AppointmentRead
): Promise<ProjectMetadata | null> {
  let metadata: ProjectMetadata = null;
  console.log("findImageFromItem called");
  const bodyResult = await new Promise<Office.AsyncResult<string>>((resolve) => {
    item.body.getAsync("html", resolve);
  });

  if (bodyResult.status === Office.AsyncResultStatus.Succeeded) {
    let readMetadata = null;
    const imageMatches = bodyResult.value.match(/<img[^>]+src="([^">]+)"/g) || [];

    for (const img of imageMatches) {
      if (img.indexOf("data:image/png;base64") > -1) {
        console.log("img = " + img);
        console.log("img.indexOf('data:image/png;base64') = " + img.indexOf("data:image/png;base64"));
        console.log("found image as dataurl");

        const imgSrc = extractImgSrc(img);
        if (!readMetadata) {
          const possibleMetadata = readMetadataFromImage(imgSrc);
          console.log("possibleMetadata:", possibleMetadata);
          if (validateMetadata(possibleMetadata)) {
            console.log("found metadata in dataurl");
            readMetadata = possibleMetadata;
          }
        }
      }
    }
    let attachments: Office.AttachmentDetailsCompose[] | Office.AttachmentDetails[] = [];

    if (item.attachments) {
      attachments = item.attachments;
    } else {
      const attachmentList = await new Promise<
        Office.AsyncResult<Office.AttachmentDetailsCompose[] | Office.AttachmentDetails[]>
      >((resolve) => {
        item.getAttachmentsAsync(resolve);
      });

      attachments = attachmentList.value;
    }

    if (!readMetadata && attachments && attachments.length > 0) {
      for (const attachment of attachments) {
        if (!readMetadata) {
          if (attachment.name.indexOf(".png") > -1 && attachment.isInline) {
            console.log(
              "found img in attachment " +
                attachment.name +
                " isInline: " +
                attachment.isInline +
                " id: " +
                attachment.id
            );
            const attachmentResult = await new Promise<Office.AsyncResult<any>>((resolve) => {
              item.getAttachmentContentAsync(attachment.id, resolve);
            });

            if (attachmentResult.status === Office.AsyncResultStatus.Succeeded) {
              const possibleMetadata = readMetadataFromImage(attachmentResult.value.content);
              if (validateMetadata(possibleMetadata)) {
                console.log("found metadata in attachment");
                readMetadata = possibleMetadata;
              }
            }
          }
        }
      }
    }

    metadata = readMetadata;
  }
  console.log("findImageFromItem returning metadata:", metadata);
  return metadata;
}
